import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Showcase from '../assets/hakkimizda.svg';
import '../index.css';
import ReactWhatsapp from 'react-whatsapp';
import { Link } from 'react-router-dom';
import WhatsAppPage from '../components/WhatsappPage';

function Contact() {
  return (
    <>
      <div 
        className="relative w-full h-720 bg-cover bg-center bg-[#1A6196]" 
        style={{ 
          backgroundImage: `url(${Showcase})`, 
          height: '720px', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <Navigation />

        {/* Breadcrumb */}
        <div className="absolute top-15 left-4 sm:left-8 md:left-20 lg:left-40 flex items-center space-x-2">
          <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            Ana Sayfa
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">•</span>
          <Link to="/iletisim" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
            İletişim
          </Link>
        </div>

        {/* Başlık */}
        <div className="flex items-left justify-left h-full pl-4 sm:pl-15 md:pl-40 lg:pl-60 pt-40">
          <h1 className="text-white text-2xl sm:text-5xl md:text-5xl lg:text-6xl">İletişim</h1>
        </div>
      </div>

      <section className="contact-section w-full max-w-[1280px] mx-auto px-4 py-10 md:py-20">
        <div className="flex flex-col items-start p-4 md:p-8 gap-4 md:gap-10 w-full md:w-[400px] h-auto md:h-[540px] bg-white shadow-md md:shadow-[0px_4px_12px_-4px_rgba(0,0,0,0.25)]">
          <h1 className="text-lg md:text-[20px] font-semibold leading-[24px] md:leading-[32px] text-[#06202C] mb-0 mt-4">
            İletişim adresimiz
          </h1>
          <div className="space-y-4">
            <div>
              <p className="text-sm md:text-[14px] font-normal">Adres</p>
              <p className="text-base md:text-[17px] font-medium text-[#06202C]">
                Balçık, 3270.Sokak Gebze/ Kocaeli
              </p>
            </div>
            <div>
              <p className="text-sm md:text-[14px] font-normal">Telefon numarası</p>
              <p className="text-base md:text-[17px] font-medium text-[#06202C]">
                (0216) 398 98 99
              </p>
            </div>
            <div>
              <p className="text-sm md:text-[14px] font-normal">Noa whatsapp hattı</p>
              <p className="text-base md:text-[17px] font-medium text-[#06202C]">
                <ReactWhatsapp 
                  number='90-0505-669-0525' 
                  message='Merhaba, Noahavalandirma.com üzerinden ulaşıyorum. Görüşmek için uygun musunuz?'
                >
                  505 669 0525 
                </ReactWhatsapp> 
              </p>
            </div>
            <div>
              <p className="text-sm md:text-[14px] font-normal">E-posta adresi</p>
              <p className="text-base md:text-[17px] font-medium text-[#06202C]">
                <a href="mailto:noa.mühendislik@gmail.com"> noa.mühendislik@gmail.com </a>
              </p>
            </div>
            <div>
              <p className="text-sm md:text-[14px] font-normal">Çalışma saatleri</p>
              <p className="text-base md:text-[17px] font-medium text-[#06202C]">
                Hafta içi: 08:00 - 18:00
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full md:w-[850px] h-[360px] md:h-[540px]">
          <iframe
            title='Google Maps'
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d878.3838351266423!2d29.44395302807923!3d40.87588335604207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2sgr!4v1741194117377!5m2!1str!2sgr"
            className="w-full h-full"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <section id="mesaj" className="flex flex-col md:flex-row justify-center items-start py-10 md:py-[120px] bg-[#F5FBFE] w-full mx-auto">
        <div className="flex flex-col md:flex-row w-full px-4 md:px-[200px]">
          <div className="flex flex-col items-start w-full md:w-[424px] mb-8 md:mb-0">
            <h1 className="text-xl md:text-[32px] font-medium leading-[28px] md:leading-[40px] text-[#06202C]">
              Bilgi İletişim Formu
            </h1>
          </div>
          <div className="flex flex-col items-start p-4 md:p-20 gap-7 w-full md:w-[872px] bg-[#FFFFFF] shadow-lg">
            <div className="flex flex-wrap w-full gap-4 md:gap-6">
              <div className="flex flex-col flex-1">
                <label className="text-sm md:text-[13px] font-medium mb-1">İsim</label>
                <input type="text" className="border border-[#ccc] p-3 rounded-md w-full" placeholder="İsim" />
              </div>
              <div className="flex flex-col flex-1">
                <label className="text-sm md:text-[13px] font-medium mb-1">Soyisim</label>
                <input type="text" className="border border-[#ccc] p-3 rounded-md w-full" placeholder="Soyisim" />
              </div>
            </div>
            <div className="flex flex-wrap w-full gap-4 md:gap-6">
              <div className="flex flex-col flex-1">
                <label className="text-sm md:text-[13px] font-medium mb-1">Telefon numarası</label>
                <input type="text" className="border border-[#ccc] p-3 rounded-md w-full" placeholder="Telefon numarası" />
              </div>
              <div className="flex flex-col flex-1">
                <label className="text-sm md:text-[13px] font-medium mb-1">E-posta adresi</label>
                <input type="email" className="border border-[#ccc] p-3 rounded-md w-full" placeholder="E-posta adresi" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <label className="text-sm md:text-[13px] font-medium mb-1">Bilgi almak istediğiniz konuyu açıklayınız</label>
              <textarea className="border border-[#ccc] p-3 rounded-md w-full h-32" placeholder="Bilgi almak istediğiniz konuyu açıklayınız"></textarea>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex items-start">
                <input type="checkbox" className="mr-2 mt-1" />
                <label className="text-sm md:text-[13px] font-medium">Kişisel Verilerin Korunması metnini okudum, kabul ediyorum.</label>
              </div>
              <button className="bg-[#06202C] text-white py-3 px-10 rounded-full hover:bg-[#0A4A6F] w-[134px] h-[52px]">
                Gönder
              </button>
            </div>
          </div>
        </div>
      </section>
      <WhatsAppPage/>
      <Footer />
    </>
  );
}

export default Contact;
