import React from 'react';
import Footer from '../components/Footer';
import Showcase from '../assets/hakkimizda.svg';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { useState } from 'react';

// JSON verilerini import ediyoruz
import services from '../hizmetler.json';

function Hizmetler() {
    const [cards, setCards] = useState(services.slice(0, 5)); // İlk 5 kartı yüklüyoruz

    const loadMore = () => {
        const nextCards = services.slice(cards.length, cards.length + 3); // Sonraki 3 kartı yüklüyoruz
        setCards(prevCards => [...prevCards, ...nextCards]);
    };

    return (
        <>
            <div className="relative w-full h-720 bg-cover bg-center bg-[#1A6196]" style={{ backgroundImage: `url(${Showcase})`, height: '720px', backgroundRepeat: 'no-repeat' }}>
                <Navigation />

                {/* Breadcrumb */}
                <div className="absolute top-15 left-4 md:left-40 flex items-center space-x-2">
                    <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
                        Ana Sayfa
                    </Link>
                    <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">•</span>
                    <Link to="/hizmetler" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
                        Hizmetlerimiz
                    </Link>
                </div>

                {/* Başlık */}
                <div className="flex items-left justify-left h-full pl-4 md:pl-60 pt-40">
                    <h1 className="text-white text-2xl sm:text-4xl md:text-5xl lg:text-6xl">Hizmetlerimiz</h1>
                </div>
            </div>

            <div className="w-full h-auto flex justify-center items-start py-0 bg-white">
                {/* List Block */}
                <div className="w-full md:w-[1680px] h-auto flex flex-col items-center bg-white z-10">
                    {/* Wrapper */}
                    <div className="w-full md:w-[1320px] h-auto flex flex-col justify-center items-start py-20 gap-20">
                        {/* Content Area */}
                        <div className="w-full md:w-[1320px] h-auto grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-12">
                            {cards.map((card, index) => (
                                <Link
                                    key={index}
                                    to={`/hizmetler/${card.id}`}
                                    className="w-full md:w-[424px] h-[514px] flex flex-col items-center justify-start text-center bg-white"
                                >
                                    <div className="w-full h-[256px] overflow-hidden">
                                        <img 
                                            src={require(`../assets/${card.image}`)} 
                                            alt={card.title} 
                                            className="w-full h-[256px] object-cover" 
                                        />
                                    </div>
                                    <div className="p-6 flex flex-col gap-4">
                                        <h3 className="text-[20px] font-inter font-medium text-left text-[#06202C]">{card.title}</h3>
                                        <p className="text-[16px] font-inter font-normal text-left text-[#06202C] opacity-80">{card.descriptionShort}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>

                        {/* Load More Button */}
                        {cards.length < services.length && (
                            <div className="w-full md:w-[1320px] h-auto flex justify-center mt-10">
                                <button
                                    onClick={loadMore}
                                    className="px-10 py-3 border-2 border-[#06202C] rounded-full text-[#1E1E1E] font-semibold text-base"
                                >
                                    Daha fazlasını gör
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Hizmetler;