import React from 'react';
import Showcase from '../assets/hakkimizda.svg';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import FaaliyetSection from '../components/FaaliyetSection';
import Footer from '../components/Footer';
import mockFaaliyetAlani from '../mockFaaliyetAlani.json'; // Yolu ayarlayın

function FaaliyetAlani() {
  return (
    <>
      <div
        className="relative w-full h-720 bg-cover bg-center bg-[#1A6196]"
        style={{ backgroundImage: `url(${Showcase})`, height: '720px', backgroundRepeat: 'no-repeat' }}
      >
        <Navigation />

        {/* Breadcrumb */}
        <div className="absolute top-15 left-4 sm:left-8 md:left-20 lg:left-40 flex items-center space-x-2">
          <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            Ana Sayfa
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">•</span>
          <Link to="/faaliyet-alani" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
            Faaliyet alanlarımız
          </Link>
        </div>

        {/* Başlık */}
        <div className="flex items-left justify-left h-full pl-4 sm:pl-15 md:pl-40 lg:pl-60 pt-40">
          <h1 className="text-white text-2xl sm:text-5xl md:text-5xl lg:text-6xl">Faaliyet Alanlarımız</h1>
        </div>
      </div>

      {/* Dynamically render FaaliyetSection components */}
      {mockFaaliyetAlani.map((faaliyet, index) => (
        <Link to={`/faaliyet/${faaliyet.id}`} key={faaliyet.id}>
          <FaaliyetSection
            imageSrc={require(`../assets/${faaliyet.images}`)} // Resimleri public/assets klasörüne koyun
            title={faaliyet.title}
            content={faaliyet.content}
            buttonText="Detaylı Bilgi"
            sectionBg={index % 2 === 0 ? "#FFFFFF" : "#F5FBFE"} // Alternating background colors
            titleBg="#06202C"
            buttonBg="#06202C"
          />
        </Link>
      ))}

      <Footer />
    </>
  );
}

export default FaaliyetAlani;