import React,{useEffect} from 'react';
import Navigation from '../components/Navigation';
import { Link, useParams } from 'react-router-dom';
import mockFaaliyetAlani from '../mockFaaliyetAlani.json';
import Footer from '../components/Footer';

function FaaliyetDetay() {
  // URL'den gelen ID'yi almak için useParams kullanıyoruz
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // mockFaaliyetAlani içinde ID'yi arayarak ilgili faaliyeti buluyoruz
  const faaliyet = mockFaaliyetAlani.find((item) => item.id === parseInt(id, 10));

  if (!faaliyet) {
    return <div>Faaliyet bulunamadı.</div>;
  }

  return (
    <>
      {/* Üst Kısım */}
      <div
        className="relative w-full bg-cover bg-center"
        style={{ backgroundColor: '#06202C', minHeight: '720px' }}
      >
        <Navigation />

        {/* Breadcrumb */}
        <div className="absolute top-15 left-4 md:left-30 flex items-center space-x-2">
          <Link
            to="/"
            className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]"
          >
            Ana Sayfa
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            •
          </span>
          <Link
            to="/faaliyetler"
            className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]"
          >
            Faaliyet Alanları
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            •
          </span>
          <span className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
            {faaliyet.title}
          </span>
        </div>

        {/* Faaliyet Başlık ve Görsel Alanı */}
        <div
          className="flex flex-col items-center justify-center text-center mt-16 mx-4 md:mx-40 gap-8"
          style={{ marginTop: '64px' }}
        >
          {/* Faaliyet Adı */}
          <h1 className="text-white text-3xl md:text-5xl font-bold">
            {faaliyet.title}
          </h1>

          {/* Faaliyet Görseli */}
          <div className="w-full md:w-[648px] h-auto md:h-[520px]">
            <img
              src={require(`../assets/${faaliyet.images}`)}
              alt={faaliyet.title}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </div>

      {/* İçerik Alanı */}
      <div className="bg-white py-8 md:py-16">
        <div className="max-w-4xl mx-auto px-4">
          <p className="text-base md:text-lg leading-7 md:leading-10 text-[#06202C] text-center" dangerouslySetInnerHTML={{ __html: faaliyet.details}}>
          
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FaaliyetDetay;