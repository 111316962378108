import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import faaliyetData from '../mockFaaliyetAlani.json';
import '../tailwind.css';
import ArrowUp from '../assets/arrow-up-right.svg';

function Faaliyet() {
  const [selectedArea, setSelectedArea] = useState(faaliyetData[0]);
  const navigate = useNavigate();

  const handleAreaClick = (area) => {
    setSelectedArea(area);
    navigate(`/faaliyet/${area.id}`);
  };

  return (
    <>
      {/* Desktop View */}
      <div className="hidden lg:flex justify-center items-center h-screen">
        <div className="w-[1680px] h-[912px] p-[120px_180px_160px] bg-white">
          <h2 className="text-2xl font-normal mb-8 text-left text-[#06202C]">Faaliyet Alanlarımız</h2>
          <div className="flex">
            <ul className="w-1/3 space-y-4">
              {faaliyetData.map((area) => (
                <li
                  key={area.id}
                  onClick={() => handleAreaClick(area)}
                  onMouseEnter={() => setSelectedArea(area)}
                  className={`text-[#06202C] hover:text-[#1881B1] text-[40px] font-medium cursor-pointer flex items-center transform transition-transform duration-300 ${
                    selectedArea.id === area.id ? 'translate-x-2' : ''
                  }`}
                >
                  <span
                    className={`inline-block w-2 h-2 rounded-full bg-[#1881B1] mr-4 ${
                      selectedArea.id === area.id ? 'visible' : 'invisible'
                    }`}
                  ></span>
                  {area.title}
                </li>
              ))}
            </ul>
            <div className="w-2/3 flex justify-center items-center">
              <img
                src={require(`../assets/${selectedArea.images}`)} 
                alt={selectedArea.title}
                className="w-[648px] h-[532px] max-w-full max-h-full"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden flex justify-center items-center h-screen">
        <div className="w-full h-auto p-4 bg-white">
          <h2 className="text-2xl font-normal mb-8 text-left text-[#06202C]">Faaliyet Alanlarımız</h2>
          <div className="flex flex-col">
            <ul className="w-full space-y-4">
              {faaliyetData.map((area) => (
                <li
                  key={area.id}
                  onClick={() => handleAreaClick(area)}
                  className={`text-[#06202C] text-lg font-medium cursor-pointer flex justify-between items-center border-b border-gray-300 transform transition-transform duration-300 hover:text-[#1881B1] py-3`}
                >
                  <div className="flex items-center">{area.title}</div>
                  <img src={ArrowUp} alt="Arrow Icon" className="w-4 h-4" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faaliyet;