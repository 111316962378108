import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import '../tailwind.css';

const WhatsAppPage = () => {
  return (
    <a
      href="https://wa.me/+905056690525"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-10 right-4 lg:bottom-15 sm:bottom-10 lg:right-10 z-50 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600 transition duration-300"
    
    >
      <FaWhatsapp size={32} />
    </a>
  );
};

export default WhatsAppPage;
