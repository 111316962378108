import React from 'react';
import '../tailwind.css';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

export default function Wrapper() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  return (
    <div className="bg-[#06202C] flex items-center justify-center h-auto lg:h-[1040px] py-10 lg:py-0" ref={ref}>
      <div className="w-full max-w-[1320px] px-4 lg:px-[180px] text-white">
        <div className="flex flex-col justify-center h-full">
          <div className="flex flex-col lg:flex-row justify-between mb-8 items-start">
            <h1 className="text-xl mb-4 lg:mb-0">Biz Kimiz?</h1>
            <div className="max-w-3xl">
              <p className='sm:text-2xl lg:text-3xl'>
              Noa Mühendislik, havalandırma ve iklimlendirme alanlarında Türkiye’nin önde gelen mühendislik, sistem entegratörlerinden biridir. Oteller, havalimanları, fabrikalar, hastaneler ve endüstriyel tesisler için yenilikçi ve enerji verimli çözümler sunarak sektörde fark yaratmaktadır.        </p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-[40px] lg:mt-[120px]">
                <div>
                  <h2 className="text-4xl lg:text-7xl  sm:text-6xl font-bold">
                    {inView && <CountUp end={30} duration={3} />}+
                  </h2>
                  <p className="font-thin">1992'den beri çalışma tecrübesi sahibiyiz</p>
                </div>
                <div>
                  <h2 className="text-4xl lg:text-7xl sm:text-6xl font-bold">
                    {inView && <CountUp end={4500} duration={3} />}+
                  </h2>
                  <p className="font-thin">4500'den fazla tamamlanmış proje</p>
                </div>
                <div>
                  <h2 className="text-4xl lg:text-7xl  sm:text-6xl font-bold">
                    {inView && <CountUp end={2000} duration={3} />}+
                  </h2>
                  <p className="font-thin">Yılda 2000'den fazla fan üretimi</p>
                </div>
                <div>
                  <h2 className="text-4xl lg:text-7xl  sm:text-6xl font-bold">
                    {inView && <CountUp end={1200} duration={3} />}+
                  </h2>
                  <p className="font-thin">1200'den fazla şehir dışı projesi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
