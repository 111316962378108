import React from 'react';
import '../tailwind.css';

function Teklif() {
  return (
    <div className="bg-white w-full min-h-[564px] md:min-h-[764px] flex items-center py-8 px-4 sm:px-8 md:py-20 md:px-20">
      <div className="w-full max-w-[1120px] mx-auto bg-[#F5FBFE] rounded-xl p-6 md:py-[80px] md:px-20 space-y-6 md:space-y-10">
        <h1 className="font-inter text-2xl md:text-4xl font-semibold text-center leading-[32px] md:leading-[52px]">
          İklimlendirme projelerindeki uzman çözüm ortağınız olarak, mükemmel müşteri memnuniyetini hedeflemekteyiz.
        </h1>
        
        <p className="text-base md:text-lg text-center leading-[24px] md:leading-[28px] text-gray-600">
          Ürünlerimizde dayanıklılık ve uzun ömrü esas alarak üretim yaparak, hem satış öncesinde 
          hem de satış sonrasında, müşterilerimizin her türlü ihtiyacında yanında olmak için çabalamaktayız.
        </p>

        <div className="flex justify-center">
          <button className="w-full max-w-[280px] md:w-[200px] h-12 md:h-[52px] bg-[#06202C] text-white font-medium rounded-full 
          text-sm md:text-base transition-transform hover:scale-105">
            Teklif Al
          </button>
        </div>
      </div>
    </div>
  );
}

export default Teklif;