import React from 'react';
import FooterLogo from '../assets/footer-logo.svg';
import PhoneIcon from '../assets/phone.svg';
import PrinterIcon from '../assets/printer.svg';
import FacebookIcon from '../assets/facebook.svg';
import InstagramIcon from '../assets/instagram.svg';
import LocationIcon from '../assets/location.svg';

function Footer() {
  return (
    <div className="w-full h-[415px] bg-[#06202C] flex flex-col lg:flex-row items-center relative">
      {/* Sol taraf - Logo */}
      <div className="hidden lg:flex h-full w-full lg:w-[488.52px] items-end justify-center lg:justify-start">
        <img src={FooterLogo} alt="Logo" className="h-[361px] w-[488.52px] object-cover" />
      </div>

      {/* Orta taraf - Bizimle iletişime geçin (Desktop only) */}
      <div className="hidden lg:flex text-white text-left flex-col flex-grow px-10">
        <h2 className="text-2xl font-semibold mb-4">Bizimle İletişime Geçin</h2>
        <p className="text-lg text-left">Noa Mühendislik</p>
        <div className="flex justify-left items-top mt-2">
          <img src={LocationIcon} alt="Location" className="h-6 w-6 mr-2" />
          <p className="text-base">
          Balçık, 3270.Sokak
            <br />
            Gebze/Kocaeli
          </p>
        </div>
        <div className="flex justify-left items-center mt-2">
          <img src={PhoneIcon} alt="Phone" className="h-6 w-6 mr-2" />
          <p className="text-base">(555) 254 78 86</p>
        </div>
        <div className="flex justify-left items-center mt-2">
          <img src={PrinterIcon} alt="Printer" className="h-6 w-6 mr-2" />
          <p className="text-base">(0216) 398 98 99</p>
        </div>
      </div>

      {/* Sağ taraf - Bizi takip edin (Desktop only) */}
      <div className="hidden lg:flex text-white text-left flex-col flex-grow px-10">
        <h2 className="text-2xl font-semibold mb-4">Bizi Takip Edin</h2>
        <p className="text-[#9A9A9A] mt-2" style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: '400', lineHeight: '26px', textAlign: 'left' }}>
          Güncel haberler için bizi sosyal medya
        </p>
        <p className="text-[#9A9A9A] mb-4" style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: '400', lineHeight: '26px', textAlign: 'left' }}>
          hesaplarımızdan takip edin.
        </p>
        <div className="flex justify-left">
          <a href="#" className="text-xl p-1 rounded-full flex items-center justify-center">
            <img src={FacebookIcon} alt="Facebook" className="h-6 w-6 h-[60px] w-[60px]" />
          </a>
          <a href="#" className="text-xl p-1 rounded-full flex items-center justify-center">
            <img src={InstagramIcon} alt="Instagram" className="h-6 w-6 h-[60px] w-[60px]" />
          </a>
        </div>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden text-white text-left px-8 w-full py-3 mt-4 bg-cover bg-center " style={{ backgroundImage: `url(${FooterLogo})` }} >
        <h2 className="text-2xl font-semibold mb-4">Bizimle İletişime Geçin</h2>
        <p className="text-lg text-left">Noa Mühendislik</p>
        <div className="flex justify-left items-top mt-2">
          <img src={LocationIcon} alt="Location" className="h-6 w-6 mr-2" />
          <p className="text-base">
            Balçık, 3270.Sokak
            <br />
            Gebze/Kocaeli
          </p>
        </div>
        <div className="flex justify-left items-center mt-2">
          <img src={PhoneIcon} alt="Phone" className="h-6 w-6 mr-2" />
          <p className="text-base">(555) 254 78 86</p>
        </div>
        <div className="flex justify-left items-center mt-2">
          <img src={PrinterIcon} alt="Printer" className="h-6 w-6 mr-2" />
          <p className="text-base">(0216) 398 98 99</p>
        </div>
        <h2 className="text-xl font-semibold mt-3 mb-2">Bizi Takip Edin</h2>
        <p className="text-[#9A9A9A] mt-2" style={{ fontFamily: 'Inter', fontSize: '13px', fontWeight: '400', lineHeight: '26px', textAlign: 'left' }}>
          Güncel haberler için bizi sosyal medya
        </p>
        <p className="text-[#9A9A9A] mb-2" style={{ fontFamily: 'Inter', fontSize: '13px', fontWeight: '400', lineHeight: '26px', textAlign: 'left' }}>
          hesaplarımızdan takip edin.
        </p>
        <div className="flex justify-left">
          <a href="#" className="text-xl p-1 rounded-full flex items-center justify-center">
            <img src={FacebookIcon} alt="Facebook" className="h-6 w-6 h-[60px] w-[60px]" />
          </a>
          <a href="#" className="text-xl p-1 rounded-full flex items-center justify-center">
            <img src={InstagramIcon} alt="Instagram" className="h-6 w-6 h-[60px] w-[60px]" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
