// NotFound.js - 404 Sayfası Bileşeni (3 saniye sonra yönlendirme ile)
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);
  
  useEffect(() => {
    // 3 saniye sonra ana sayfaya yönlendir
    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 3000);
    
    // Geri sayım sayacı
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);
    
    // Component unmount olduğunda timer'ları temizle
    return () => {
      clearTimeout(redirectTimer);
      clearInterval(countdownInterval);
    };
  }, [navigate]);
  
  return (
    <>
      <div className="w-full min-h-screen flex flex-col items-center justify-center bg-[#F5FBFE] py-20 px-4">
        <div className="max-w-2xl w-full text-center">
          <h1 className="text-8xl font-bold text-[#1A6196] mb-6">404</h1>
          <h2 className="text-3xl font-semibold text-[#06202C] mb-4">Sayfa Bulunamadı</h2>
          <p className="text-lg text-[#384D56] mb-8">
            Aradığınız sayfa mevcut değil veya taşınmış olabilir.
          </p>
          <p className="text-lg text-[#384D56] font-medium mb-8">
            {countdown} saniye içinde ana sayfaya yönlendirileceksiniz...
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link 
              to="/" 
              className="px-8 py-3 bg-[#1A6196] text-white rounded-full font-medium hover:bg-[#155180] transition-colors"
            >
              Ana Sayfaya Dön
            </Link>
            <Link 
              to="/iletisim" 
              className="px-8 py-3 border-2 border-[#06202C] text-[#06202C] rounded-full font-medium hover:bg-[#06202C] hover:text-white transition-colors"
            >
              Bize Ulaşın
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;