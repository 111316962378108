import React from 'react';
import '../tailwind.css';
import DBschenker from '../assets/DBschenker.jpg';
import Akasya from '../assets/akasya.jpg';
import Ekol from '../assets/ekol.jpg';
import Greif from '../assets/greif.jpg';
import saw from '../assets/saw.jpg';
import tabgıda from '../assets/tabgıda.jpg';

const cards = [
  { id: 1, logo: DBschenker, name: 'DB Schenker' },
  { id: 2, logo: Akasya, name: 'Akasya' },
  { id: 3, logo: Ekol, name: 'Ekol' },
  { id: 4, logo: Greif, name: 'Greif' },
  { id: 5, logo: saw, name: 'SAW' },
  { id: 6, logo: tabgıda, name: 'Tab Gıda' },
];

function Referans() {
  return (
    <div className="flex justify-center items-center min-h-screen bg-[#06202C]">
      <div className="bg-[#06202C] w-full max-w-[1680px] py-10 sm:py-16 md:py-20 lg:py-[120px] px-4 sm:px-6 md:px-8 gap-8 md:gap-16">
        <div className="w-full max-w-[1320px] mx-auto gap-8 md:gap-16">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end text-white w-full gap-4 sm:gap-5">
            <div className="text-left w-full sm:w-auto md:w-[648px] mb-4 sm:mb-0">
              <h2 className="font-inter text-xl sm:text-2xl font-medium leading-7 sm:leading-8">Referanslarımız</h2>
              <p className="font-inter text-base sm:text-lg md:text-xl lg:text-2xl font-medium leading-6 sm:leading-7 md:leading-8 lg:leading-10 mt-2 sm:mt-4">
                Uzmanlığımız ve uluslararası çözümlerimizle güçlendirdiğimiz olağanüstü işletmelere tanık olun. Biz birlikte büyürüz.
              </p>
            </div>
          </div>

          {/* Responsive Card Grid */}
          <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-3 xs:gap-4 sm:gap-6 mt-6 sm:mt-8">
            {cards.map((card) => (
              <div
                key={card.id}
                className="bg-[#052432] flex justify-center items-center w-full h-[120px] sm:h-[150px] md:h-[180px] lg:h-[250px] p-4 rounded-sm"
              >
                <img 
                  src={card.logo} 
                  alt={`${card.name} logo`} 
                  className="max-h-full max-w-full object-contain" 
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Referans;