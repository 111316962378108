import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import mockProducts from '../mockProducts.json';
import UrunlerSlider from '../components/UrunlerSlider';
import Footer from '../components/Footer';

function ProductDetail() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('Ürün Bilgisi');
  
  // Sayfa yüklendiğinde en üste scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const product = mockProducts.find((item) => item.id === parseInt(id, 10));

  if (!product) {
    return (
      <>
        <Helmet>
          <title>Ürün Bulunamadı | Noa Muhendislik</title>
          <meta name="description" content="Aradığınız ürün bulunamadı. Diğer ürünlerimize göz atabilirsiniz." />
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <div className="p-4 text-center">Ürün bulunamadı.</div>
      </>
    );
  }

  const tabs = ['Ürün Bilgisi', 'Teknik Özellikler', 'Ürün Görselleri'];
  
  // Yapılandırılmış veri (Schema.org) 
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.title,
    "description": product.description.replace(/<[^>]*>?/gm, ''), // HTML taglarını temizle
    "image": `${window.location.origin}/assets/${product.imageDetail}`,
    "category": product.category,
    "offers": {
      "@type": "Offer",
      "url": window.location.href,
      "availability": "https://schema.org/InStock"
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${product.title} | ${product.category} | Noa Muhendislik`}</title>
        <meta name="description" content={`${product.title} - ${product.description.replace(/<[^>]*>?/gm, '').substring(0, 160)}...`} />
        <meta name="keywords" content={`${product.title}, ${product.category}, ürün detayları, teknik özellikler`} />
        <link rel="canonical" href={`${window.location.origin}/urunler/${id}`} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={product.description.replace(/<[^>]*>?/gm, '').substring(0, 160)} />
        <meta property="og:image" content={`${window.location.origin}/assets/${product.imageDetail}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={product.title} />
        <meta name="twitter:description" content={product.description.replace(/<[^>]*>?/gm, '').substring(0, 160)} />
        <meta name="twitter:image" content={`${window.location.origin}/assets/${product.imageDetail}`} />
        
        {/* Structured Data - JSON-LD */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div
        className="relative w-full bg-cover bg-center"
        style={{ backgroundColor: '#06202C', minHeight: '500px' }}
      >
        <Navigation />

        {/* Breadcrumb - Schema.org mikro verileri eklenmiş */}
        <nav aria-label="Breadcrumb" className="px-4 md:px-8 lg:px-16 xl:absolute xl:top-15 xl:left-40 flex flex-wrap items-center space-x-2 pt-6 xl:pt-0">
          <ol itemScope itemType="https://schema.org/BreadcrumbList" className="flex items-center space-x-2">
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <Link
                to="/"
                className="text-[#DDDDDD] font-inter text-xs sm:text-sm md:text-[14px] font-semibold leading-tight"
                itemProp="item"
              >
                <span itemProp="name">Ana Sayfa</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <span className="text-[#DDDDDD] font-inter text-xs sm:text-sm md:text-[14px] font-semibold leading-tight">•</span>
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <Link
                to="/urunler"
                className="text-[#F9F9F9] font-inter text-xs sm:text-sm md:text-[14px] font-semibold leading-tight"
                itemProp="item"
              >
                <span itemProp="name">Ürünlerimiz</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <span className="text-[#DDDDDD] font-inter text-xs sm:text-sm md:text-[14px] font-semibold leading-tight">•</span>
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <span className="text-[#F9F9F9] font-inter text-xs sm:text-sm md:text-[14px] font-semibold leading-tight" itemProp="name">
                {product.title}
              </span>
              <meta itemProp="position" content="3" />
            </li>
          </ol>
        </nav>

        {/* Ürün Başlık ve Görsel Alanı - Semantic HTML eklendi */}
        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start px-4 md:px-8 lg:px-16 xl:mx-40 gap-8 lg:gap-16 py-8 lg:py-16 mt-8 md:mt-12 xl:mt-16">
          {/* Sol Taraf - Ürün Başlığı ve Kategorisi */}
          <div className="flex flex-col space-y-4 w-full lg:w-1/2 order-2 lg:order-1">
            {/* Kategori */}
            <div
              className="inline-block bg-[#72C5EC] text-black font-inter text-sm md:text-[16px] font-semibold rounded-full px-4 py-1 md:px-4 md:py-2"
              style={{
                width: 'fit-content',
                backgroundColor: '#72C5EC',
              }}
            >
              {product.category}
            </div>
            {/* Ürün Başlığı */}
            <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold">
              {product.title}
            </h1>
          </div>

          {/* Sağ Taraf - Ürün Görseli */}
          <div className="w-full md:w-3/4 lg:w-1/2 order-1 lg:order-2">
            <img
              src={require(`../assets/${product.imageDetail}`)} 
              alt={`${product.title} - ${product.category} ürün görseli`}
              className="w-full h-auto object-cover rounded-lg max-h-80 md:max-h-96 lg:max-h-full"
              loading="eager"
              width="600"
              height="400"
            />
          </div>
        </div>
      </div>

      {/* Tabs Section - ARIA eklenmiş */}
      <div className="w-full flex justify-center py-8 md:py-16 px-4">
        <div className="w-full max-w-3xl flex justify-center">
          <div role="tablist" aria-label="Ürün Bilgileri" className="w-full max-w-xl bg-[#F5FBFE] flex flex-wrap md:flex-nowrap justify-center md:justify-around items-center rounded-full p-2">
            {tabs.map((tab) => (
              <button
                key={tab}
                role="tab"
                id={`tab-${tab.toLowerCase().replace(/\s+/g, '-')}`}
                aria-selected={activeTab === tab}
                aria-controls={`panel-${tab.toLowerCase().replace(/\s+/g, '-')}`}
                onClick={() => setActiveTab(tab)}
                className={`m-1 px-3 py-2 md:w-[180px] md:h-[40px] rounded-full font-inter font-medium text-sm md:text-[16px] text-center ${
                  activeTab === tab
                    ? 'bg-[#06202C] text-white'
                    : 'bg-[#F5FBFE] text-black'
                } hover:bg-[#06202C] hover:text-white transition-all duration-300`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Tab Content - ARIA ve semantic HTML eklendi */}
      <div className="w-full max-w-4xl mx-auto py-6 md:py-10 px-4">
        {activeTab === 'Ürün Bilgisi' && (
          <section 
            role="tabpanel" 
            id={`panel-ürün-bilgisi`}
            aria-labelledby="tab-ürün-bilgisi"
            className="text-base md:text-lg leading-7 md:leading-8 text-[#06202C]"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        )}
        {activeTab === 'Teknik Özellikler' && (
          <section 
            role="tabpanel" 
            id={`panel-teknik-özellikler`}
            aria-labelledby="tab-teknik-özellikler"
            className="text-base md:text-lg leading-7 md:leading-8 text-[#06202C]"
          >
            <h2 className="text-xl md:text-2xl font-bold mb-4">{product.title} - Teknik Özellikler</h2>
            <p>Teknik özellikler bilgisi burada yer alacak.</p>
          </section>
        )}
        {activeTab === 'Ürün Görselleri' && (
          <section 
            role="tabpanel" 
            id={`panel-ürün-görselleri`}
            aria-labelledby="tab-ürün-görselleri"
          >
            <h2 className="text-xl md:text-2xl font-bold mb-4">{product.title} Görselleri</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {product.images.map((image, index) => (
                <figure key={index}>
                  <img
                    src={require(`../assets/${image}`)} 
                    alt={`${product.title} - Görsel ${index + 1}`}
                    className="w-full h-auto rounded-lg"
                    loading={index < 2 ? "eager" : "lazy"}
                    width="500"
                    height="375"
                  />
                  <figcaption className="text-sm text-gray-600 mt-1">
                    {product.title} - Görsel {index + 1}
                  </figcaption>
                </figure>
              ))}
            </div>
          </section>
        )}
      </div>

      {/* İlgili Ürünler */}
      <section aria-labelledby="related-products-heading">
        <h2 id="related-products-heading" className="sr-only">Benzer Ürünler</h2>
        <UrunlerSlider />
      </section>
      
      <Footer />
    </>
  );
}

export default ProductDetail;