import React from 'react';
import Faaliyetpic from '../assets/faaliyet-section.jpeg';

const FaaliyetSection = ({
  imageSrc = Faaliyetpic,
  title = 'Başlık Alanı',
  content = 'Bu, içerik alanı için bir örnek metindir. İçeriğinizi buraya yerleştirin.',
  buttonText = 'Daha Fazla Bilgi',
  sectionBg = '#FFFFFF',
  titleBg = '#06202C',
  buttonBg = '#06202C',
}) => {
  return (
    <section
      className="w-full mx-auto py-20 px-4 md:px-0"
      style={{ backgroundColor: sectionBg }}
    >
      <div className="flex flex-col md:flex-row justify-between mx-auto h-auto max-w-[1320px] gap-8">
        {/* Sol taraftaki fotoğraf alanı */}
        <div className="w-full md:w-[648px] h-[300px] md:h-[560px]">
          <img
            src={imageSrc}
            alt="Section"
            className="w-full h-full object-cover rounded-md"
          />
        </div>
        {/* Sağ taraftaki içerik alanı */}
        <div className="w-full md:w-[496px] flex flex-col justify-center">
          <h1
            className="font-inter text-[28px] md:text-[40px] font-medium leading-[36px] md:leading-[52px] mb-4 md:mb-6"
            style={{ color: titleBg }}
          >
            {title}
          </h1>
          <p className="font-inter text-[16px] md:text-[20px] font-normal leading-[24px] md:leading-[30px] mb-4 md:mb-6">
            {content}
          </p>
          <button
            className="w-full md:w-[200px] py-3 md:py-4 px-6 md:px-10 rounded-full text-white"
            style={{ backgroundColor: buttonBg }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FaaliyetSection;
