import React, { useState, useEffect } from 'react';
import Showcase from '../assets/hakkimizda.svg';
import { Link, useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import mockProducts from '../mockProducts.json';
import WhatsAppPage from '../components/WhatsappPage';

export default function Products() {
  // SEO için URL parametreleri ve sayfa başlığı yönetimi
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryParam = queryParams.get('category');
  
  // Kategori kontrolü ve ayarlanması
  const hasCategory = mockProducts.length > 0 && 'category' in mockProducts[0];
  const categories = hasCategory ? 
    [...new Set(mockProducts.map(product => product.category))] : 
    ["Varsayılan Kategori"];
  
  // URL'den gelen kategori parametresi varsa onu kullan, yoksa ilk kategoriyi seç
  const [selectedCategory, setSelectedCategory] = useState(
    categoryParam && categories.includes(categoryParam) 
      ? categoryParam 
      : (categories.length > 0 ? categories[0] : "")
  );
  
  useEffect(() => {
    // Sayfa başlığını ve meta açıklamasını dinamik olarak güncelle
    const pageTitle = selectedCategory 
      ? `${selectedCategory} | Noa Mühendislik Ürünleri` 
      : "Ürünlerimiz | Noa Mühendislik";
    
    document.title = pageTitle;
    
    // Meta açıklama etiketini güncelle
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content', 
        `Noa Mühendislik ${selectedCategory || 'havalandırma'} ürünleri. Kaliteli ve dayanıklı endüstriyel havalandırma çözümleri için bizi tercih edin.`
      );
    }
    
    // Sayfanın üstüne kaydır
    window.scrollTo(0, 0);
    
    // Kategori değiştiğinde URL'i güncelle (sayfa yenileme olmadan)
    if (selectedCategory) {
      const newUrl = `/urunler?category=${encodeURIComponent(selectedCategory)}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [selectedCategory]);
  
  // Kategoriye göre filtrelenmiş ürünler
  const filteredProducts = selectedCategory ?
    mockProducts.filter(product => product.category === selectedCategory) :
    [];

  return (
    <>
      {/* Hero Section - Semantik header etiketi kullanıldı */}
      <header className="relative w-full h-720 bg-cover bg-center bg-[#1A6196]"
        style={{
          backgroundImage: `url(${Showcase})`,
          height: '720px',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Navigation/>
        
        {/* Breadcrumb - Semantik nav etiketi kullanıldı */}
        <nav aria-label="breadcrumb" className="absolute top-15 left-4 sm:left-8 md:left-20 lg:left-40 flex items-center space-x-2">
          <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            Ana Sayfa
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]" aria-hidden="true">•</span>
          <Link to="/urunler" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]" aria-current="page">
            Ürünlerimiz
          </Link>
        </nav>

        {/* Başlık - Doğru h1 etiketiyle */}
        <div className="flex items-left justify-left h-full pl-4 sm:pl-15 md:pl-40 lg:pl-60 pt-40">
          <h1 className="text-white text-2xl sm:text-5xl md:text-5xl lg:text-6xl">Ürünlerimiz</h1>
        </div>
      </header>

      {/* Ana içerik - Semantik main etiketi */}
      <main className="w-full h-auto bg-[#F5FBFE] flex justify-center items-start py-20 px-4 md:px-0">
        <div className="w-full md:w-[1320px] h-auto flex flex-col md:flex-row gap-6">
          {/* Mobil Kategori Seçimi */}
          <div className="md:hidden w-full mb-6" role="navigation" aria-label="Kategori seçimi">
            {categories.length > 0 ? (
              <select 
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="w-full p-3 border-2 border-[#2177A5] rounded-lg"
                aria-label="Kategori seçin"
              >
                {categories.map((category, index) => (
                  <option key={index} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            ) : (
              <div className="p-3 border-2 border-[#2177A5] rounded-lg bg-gray-100">
                Kategoriler yükleniyor...
              </div>
            )}
          </div>

          {/* Masaüstü Kategori Listesi - Sidebar semantik aside etiketi */}
          <aside className="hidden md:block w-[312px] h-auto bg-white p-8 space-y-3" role="navigation" aria-label="Ürün kategorileri">
            <h2 className="text-lg font-semibold mb-4 text-[#06202C]">Kategoriler</h2>
            {categories.length > 0 ? (
              <ul>
                {categories.map((category, index) => (
                  <li key={index}>
                    <button
                      className={`w-full p-3 text-left ${
                        selectedCategory === category
                          ? 'bg-[#F5FBFE] text-[#2177A5] font-medium'
                          : 'text-[#06202C]'
                      } hover:bg-[#F5FBFE] hover:text-[#2177A5]`}
                      onClick={() => setSelectedCategory(category)}
                      aria-selected={selectedCategory === category}
                      aria-controls="product-list"
                    >
                      {category}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="p-3 text-center">
                Kategoriler yükleniyor...
              </div>
            )}
          </aside>

          {/* Ürün Listesi - ARIA ile ilişkilendirme */}
          <section 
            id="product-list" 
            className="w-full md:w-[984px] h-auto" 
            aria-live="polite"
            aria-label={`${selectedCategory} ürünleri`}
          >
            <h2 className="sr-only">{selectedCategory} Ürünleri</h2>
            
            {filteredProducts.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {filteredProducts.map((product) => (
                  <article key={product.id} className="w-full product-card">
                    <Link 
                      to={`/urun/${product.id}`}
                      className="w-full block"
                      aria-labelledby={`product-title-${product.id}`}
                    >
                      <div className="w-full md:w-[312px] h-[424px] bg-white shadow-lg rounded-lg overflow-hidden mx-auto">
                        <img
                          src={require(`../assets/${product.images[0]}`)} 
                          alt={`${product.title} ürünü görseli`}
                          className="w-full h-[240px] object-cover"
                          loading="lazy"
                          onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/312x240?text=Resim+Bulunamadı';
                            e.target.alt = 'Resim bulunamadı';
                          }}
                        />
                        <div className="p-4">
                          <span className="text-[15px] font-inter font-normal text-left opacity-80">
                            {product.category}
                          </span>
                          <h3 
                            id={`product-title-${product.id}`}
                            className="text-[20px] font-inter font-medium leading-6 text-left text-[#06202C] mt-2"
                          >
                            {product.title}
                          </h3>
                          <p className="text-[16px] font-inter font-normal text-left leading-[22px] text-[#06202C] opacity-80 mt-2 line-clamp-3">
                            {product.cardDescription}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </article>
                ))}
              </div>
            ) : (
              <p className="text-center py-12 text-lg text-gray-600">
                {categories.length > 0 
                  ? "Bu kategoriye ait ürün bulunmamaktadır." 
                  : "Kategoriler yükleniyor..."}
              </p>
            )}
          </section>
        </div>
      </main>
      
      <WhatsAppPage/>
      <Footer />
      
      {/* Ürün Kategorisi için JSON-LD Yapılandırılmış Veri */}
      <script 
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": filteredProducts.map((product, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "item": {
                "@type": "Product",
                "name": product.title,
                "description": product.cardDescription,
                "image": `https://www.noamuhendislik.com/assets/${product.images[0]}`,
                "url": `https://www.noamuhendislik.com/urun/${product.id}`,
                "brand": {
                  "@type": "Brand",
                  "name": "Noa Mühendislik"
                },
                "category": product.category
              }
            }))
          })
        }}
      />
      
      {/* BreadcrumbList için JSON-LD */}
      <script 
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Ana Sayfa",
                "item": "https://www.noamuhendislik.com"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Ürünlerimiz",
                "item": "https://www.noamuhendislik.com/urunler"
              },
              selectedCategory ? {
                "@type": "ListItem",
                "position": 3,
                "name": selectedCategory,
                "item": `https://www.noamuhendislik.com/urunler?category=${encodeURIComponent(selectedCategory)}`
              } : null
            ].filter(Boolean)
          })
        }}
      />
    </>
  );
}