import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import Showcase from '../assets/hakkimizda.svg';
import Footer from '../components/Footer';
import RoboticArm from '../assets/robotic-arm.jpg';
import chevronDown from '../assets/chevron-down.svg';
import chevronUp from '../assets/chevron-up.svg';
import '../tailwind.css';
import { Link } from 'react-router-dom';
import hakkimizdaImage from '../assets/hakkimizda_list.jpg';

export default function About() {
  const [openDrawer, setOpenDrawer] = useState(null);

  const toggleDrawer = (index) => {
    setOpenDrawer(openDrawer === index ? null : index);
  };

  return (
    <>
      {/* Hero Bölümü - Orijinal yükseklik korunarak */}
      <div className="relative w-full bg-cover bg-center bg-[#1A6196]" 
           style={{ 
             backgroundImage: `url(${Showcase})`, 
             height: '720px', 
             backgroundRepeat: 'no-repeat',
             backgroundSize: 'cover'
           }}>
        <Navigation />

        {/* Breadcrumb - Orijinal pozisyon korundu, mobil için adjustments */}
        <div className="absolute top-15 left-4 sm:left-8 md:left-20 lg:left-40 flex items-center space-x-2">
          <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
            Ana Sayfa
          </Link>
          <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">•</span>
          <Link to="/hakkimizda" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
            Hakkımızda
          </Link>
        </div>

        {/* Başlık - Orijinal pozisyon korundu, mobil için adjustments */}
        <div className="flex items-left justify-left h-full pl-4 sm:pl-15 md:pl-40 lg:pl-60 pt-40">
          <h1 className="text-white text-2xl sm:text-5xl md:text-5xl lg:text-6xl">Hakkımızda</h1>
        </div>
      </div>

      {/* Şirket Bilgileri Bölümü - Responsive */}
      <div className="relative w-full px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="relative w-full mx-auto max-w-screen-xl">
          <div className="w-full flex flex-col lg:flex-row lg:items-start">
            <div className="w-full lg:w-1/2 text-left lg:pr-4 flex flex-col justify-center mt-12 sm:mt-16 lg:mt-20">
              <h1 className="text-black font-inter text-xl sm:text-2xl font-medium leading-8 mb-4 sm:mb-8">Noa Hakkında</h1>
            </div>
            <div className="w-full lg:w-1/2 text-left lg:pl-4 mt-4 sm:mt-8 lg:mt-20 mb-10 sm:mb-16 lg:mb-20">
              <p className="font-inter text-xl sm:text-2xl md:text-3xl font-bold leading-tight mb-4 sm:mb-8">
                Türkiye'nin önde gelen mühendislik şirketlerinden biri olan Noa Mühendislik, havalandırma ve iklimlendirme sektörlerine katma değerli ürün ve hizmetler sunan bir mühendislik firması, çözüm sağlayıcısı ve teknoloji odaklı bir iş ortağıdır.
              </p>
              <p className="font-inter text-base sm:text-lg font-normal leading-relaxed mb-4">
                Noa Mühendislik 1992 yılında İstanbul'da kurulmuştur. Sultanbeyli'de faaliyet gösteren şirket Balçık'ta inşaası yapılmış yeni yerinde hizmet vermeye başladı. Noa Mühendislik'in temel faaliyet alanı, oteller, havalimanları, okullar, fabrikalar, hastaneler, restoranlar ve otoparklar gibi çeşitli yapı ve tesislere; Hücreli Tip Aspiratör, Radyal Fanlar, Kanal Tipi Fanlar, Çatı Tipi Fanlar, Filtre Sistemleri, Ekoloji Üniteleri, Isı Geri Kazanım Cihazları, Klima Santralleri, Aksiyel Fanlar ve Pervane gibi ürünlerle havalandırma, iklimlendirme ve enerji verimliliği çözümleri sunmaktır.
              </p>
              <p className="font-inter text-base sm:text-lg font-normal leading-relaxed mt-4">
                Noa Mühendislik bu sektör ve pazarlarda faaliyetleri doğrultusunda teknolojiler geliştirmekte, sektöründe öncü şirketler ile uzun süreli çözüm ortaklıkları kurarak büyük sistem entegrasyonu projeleri gerçekleştirmekte, kendine ait tasarım, ürün ve çözümler ile uçtan uca ve anahtar teslim ürün, hizmet ve sistemlerin satışlarını gerçekleştirmektedir.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Robotik Kol Görsel Bölümü - Responsive */}
      <div className="relative w-full h-80 sm:h-96 md:h-[500px] lg:h-[800px] mx-auto overflow-hidden">
        <img
          src={RoboticArm}
          alt="Robotic Arm Welding"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute left-4 sm:left-8 md:left-12 lg:left-20 bottom-10 sm:bottom-16 md:bottom-24 lg:bottom-32 px-2 sm:px-5 max-w-full sm:max-w-3/4 md:max-w-2/3">
          <p className="text-white font-inter text-2xl sm:text-3xl md:text-4xl lg:text-5xl leading-tight font-medium text-left">
            Elde ettiğimiz bilgi ve deneyimle, <br className="hidden sm:block" />
            geleceğe emin adımlarla ilerliyoruz.
          </p>
        </div>
      </div>

      {/* Üretim Aşamaları Bölümü - Eşit yükseklikler için düzeltildi */}
      <div className="bg-white py-10 sm:py-16 lg:py-20 px-4">
        <div className="max-w-screen-lg mx-auto">
          <div className="text-left mb-6">
            <h2 className="font-inter text-lg sm:text-xl md:text-2xl font-medium leading-tight">
              Üretim Aşamalarımız
            </h2>
            <p className="font-inter text-xl sm:text-2xl md:text-3xl font-medium leading-tight mt-2 mb-6">
              Fan üretiminde yüksek teknoloji ve hassasiyetin hikayesini anlatıyoruz.
            </p>
          </div>
          
          {/* Eşit yükseklikli container */}
          <div className="flex flex-col lg:flex-row lg:items-stretch gap-8">
            {/* Sol taraf - Image */}
            <div className="w-full lg:w-1/2">
              <div className="w-full h-full bg-cover bg-center" 
                   style={{ backgroundImage: `url(${hakkimizdaImage})`, minHeight: '600px' }}>
              </div>
            </div>
            
            {/* Sağ taraf - Accordion */}
            <div className="w-full lg:w-1/2 flex flex-col justify-between">
              {[...Array(6)].map((_, index) => (
                <div key={index} className="mb-6">
                  <div
                    className="flex justify-between items-center cursor-pointer w-full"
                    onClick={() => toggleDrawer(index)}
                  >
                    <div className="flex items-center space-x-2">
                      <span className="font-inter text-base sm:text-lg md:text-xl font-medium leading-tight text-[#5D5D5D]">
                        0{index + 1}
                      </span>
                      <h3 className="font-inter text-lg sm:text-xl md:text-2xl font-medium leading-tight ml-2">
                        {['Tasarım ve Mühendislik', 'Malzeme Seçimi', 'Üretim Süreci', 'Montaj', 'Kalite Kontrol', 'Paketleme ve Nakliye'][index]}
                      </h3>
                    </div>
                    <img
                      src={openDrawer === index ? chevronUp : chevronDown}
                      alt="Toggle"
                      className="w-5 h-5 sm:w-6 sm:h-6 flex-shrink-0"
                    />
                  </div>
                  <div
                    className={`transition-all duration-500 ease-in-out overflow-hidden ${openDrawer === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}
                  >
                    <p className="font-inter text-base sm:text-lg font-normal leading-relaxed text-[#384D56] mt-2 pr-2">
                      Lorem ipsum dolor sit amet consectetur. Ultricies justo placerat id nibh libero. Sed non magna aliquam purus diam lacinia habitant lectus. Diam tempus diam elementum nisl potenti id. Vel elementum mattis aenean ultrices sed facilisis nunc semper morbi.
                    </p>
                  </div>
                  {/* Separator Line */}
                  <div className="bg-[#ECECEC] w-full h-[1px] mt-4"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}