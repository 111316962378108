import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage'; // Anasayfa bileşeni
import About from './pages/About'; // Hakkımızda sayfası bileşeni
import Contact from './pages/Contact'; // İletişim bileşeni
import FaaliyetAlani from './pages/FaaliyetAlani';
import Products from './pages/Products';
import Hizmetler from './pages/Hizmetler';
import ProductDetail from './pages/ProductDetail';
import FaaliyetDetay from './pages/FaaliyetDetay';
import HizmetDetay from './pages/HizmetDetay';
import NotFound from './pages/NotFound';
import RouteChangeTracker from './pages/RouteChangeTracker';
function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/faaliyet-alani" exact element={<FaaliyetAlani />} />
          <Route path="/faaliyet/:id" element={<FaaliyetDetay />} />
          <Route path="/urunler" exact element={<Products />} />
          <Route path="/urun/:id" element={<ProductDetail />} />
          <Route path="/hizmetler" exact element={<Hizmetler />} />
          <Route path="/hizmetler/:id" element={<HizmetDetay />} />
          <Route path="*" element={<NotFound />} />

        </Routes>

    </Router>
 
  );
}

export default App;
