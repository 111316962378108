import React from 'react';
import Navigation from '../components/Navigation';
import UrunlerSlider from '../components/UrunlerSlider';
import '../tailwind.css';
import Wrapper from '../components/Wrapper';
import Footer from '../components/Footer';
import Teklif from '../components/Teklif';
import Referans from '../components/Referans';
import Faaliyet from '../components/Faaliyet';
import HizmetlerSlider from '../components/HizmetlerSlider';
import VideoHeader from '../assets/video-header.mp4';
import RoboticArm from '../assets/robotic-arm.jpg';
import WhatsAppPage from '../components/WhatsappPage';

export default function HomePage() {
  return (
    <>
      <div className="relative w-full h-screen overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover filter brightness-50"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={VideoHeader} type="video/mp4" />
          Tarayıcınız video etiketini desteklemiyor.
        </video>
        <Navigation />
        <div className="absolute bottom-10 left-4 sm:bottom-28 sm:left-0 md:bottom-32 md:left-10 lg:bottom-40 lg:left-10 xl:left-1 text-white font-bold leading-tight max-w-[1680px] min-w-[375px] mx-auto px-4 sm:py-30 sm:px-7 md:px-10 lg:px-16 xl:px-20">
  <p className="text-4xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
    Geleceği <br />
    Yenilikçi Çözümlerle <br />
    Şekillendiriyoruz
  </p>
</div>

      </div>
      <Wrapper />
      <UrunlerSlider />
      <div className="relative w-full h-[800px] mx-auto overflow-hidden">
        <img
          src={RoboticArm}
          alt="Robotic Arm Welding"
          className="w-full h-full object-cover brightness-50"
        />
        <div className="absolute sm:left-8 left-20 bottom-32 md:left-10 md:bottom-16 lg:left-20 lg:bottom-24 px-5">
          <p className="text-white font-inter text-[52px] sm:text-[30px] leading-[35px] font-medium md:text-[32px] md:leading-[40px] lg:text-[52px] lg:leading-[64px] text-left">
            Elde ettiğimiz bilgi ve deneyimle, <br />
            geleceğe emin adımlarla ilerliyoruz.
          </p>
        </div>
      </div>
      <HizmetlerSlider />
      <Faaliyet />
      <Referans />
      <Teklif />
      <Footer />
      <WhatsAppPage/>    

    </>
  );
}
