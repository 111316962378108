import React, { useState, useRef, useEffect } from 'react';
import logo from '../assets/Logo.svg';
import arrow from '../assets/Vector.svg'; 
import chevronDown from '../assets/chevron-down.svg';
import '../tailwind.css'; 
import '../index.css';
import { Link } from 'react-router-dom';

// Import mock data (paths may need adjustment based on your project structure)
import  mockFaaliyetAlani  from '../mockFaaliyetAlani.json';
import mockProducts from '../mockProducts.json';
import hizmetler from '../hizmetler.json';

function Navigation() {
    // Desktop menüler için aktif menüyü tutan state
    const [activeDesktopMenu, setActiveDesktopMenu] = useState(null);

    // Mobil menü state'leri
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    
    // Data state'leri
    const [faaliyetler, setFaaliyetler] = useState([]);
    const [urunler, setUrunler] = useState([]);
    const [hizmetlerimiz, setHizmetlerimiz] = useState([]);

    // Yeni: Hover yapılan item'ın index'ini tutacak state'ler
    const [currentFaaliyetIndex, setCurrentFaaliyetIndex] = useState(0);
    const [currentUrunIndex, setCurrentUrunIndex] = useState(0);
    const [currentHizmetIndex, setCurrentHizmetIndex] = useState(0);

    // Yalnızca desktop menü kapanma gecikmesi için ref
    const menuTimeoutRef = useRef(null);

    // Data yükleme
    useEffect(() => {
        // Faaliyetleri yükle (ilk 7 adet)
        setFaaliyetler(mockFaaliyetAlani.slice(0, 7));
        
        // Ürünleri yükle (ilk 7 adet)
        setUrunler(mockProducts.slice(0, 7));
        
        // Hizmetleri yükle (tümü)
        setHizmetlerimiz(hizmetler);
    }, []);

    // Desktop: bir menüye mouse girince
    const handleDesktopMenuEnter = (menuName) => {
        if (menuTimeoutRef.current) {
            clearTimeout(menuTimeoutRef.current);
        }
        setActiveDesktopMenu(menuName);
    };

    // Desktop: menüden mouse çıkınca
    const handleDesktopMenuLeave = () => {
        menuTimeoutRef.current = setTimeout(() => {
            setActiveDesktopMenu(null);
        }, 200);
    };

    // Desktop: mega menu üzerinde tekrar mouse girince (kapanmayı iptal etmek isteyebilirsiniz)
    const handleMegaMenuMouseEnter = () => {
        if (menuTimeoutRef.current) {
            clearTimeout(menuTimeoutRef.current);
        }
    };

    // Desktop: mega menu üzerinden mouse çıkınca (kapat)
    const handleMegaMenuMouseLeave = () => {
        setActiveDesktopMenu(null);
    };

    // Mobil menü aç/kapa
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setActiveSubMenu(null);
    };

    // Mobil alt menü seçimi
    const handleSubMenuClick = (menu) => {
        setActiveSubMenu(menu);
    };

    // Mobil alt menüden ana menüye geri dön
    const handleBackToMainMenu = () => {
        setActiveSubMenu(null);
    };

    // Yeni: Faaliyet üzerine gelindiğinde
    const handleFaaliyetHover = (index) => {
        setCurrentFaaliyetIndex(index);
    };

    // Yeni: Ürün üzerine gelindiğinde
    const handleUrunHover = (index) => {
        setCurrentUrunIndex(index);
    };

    // Yeni: Hizmet üzerine gelindiğinde
    const handleHizmetHover = (index) => {
        setCurrentHizmetIndex(index);
    };

    // Desktop menü arka planı koyulaşsın mı?
    // Aktif menü varsa, navbar arka planı koyu olsun, yoksa şeffaf
    const navbarBgClass = activeDesktopMenu
        ? 'bg-gray-900 transition-all duration-500'
        : 'bg-transparent transition-all duration-500';

    return (
        <div className={`navbar flex justify-between items-center px-4 sm:px-8 lg:px-[80px] py-[20px] text-white relative ${navbarBgClass}`}>
            {/* Logo */}
            <div className="logo">
                <a href="/">
                    <img src={logo} alt="Logo" className="w-[89px] h-[80px]" />
                </a>
            </div>

            {/* Desktop LINKS */}
            <div className="hidden lg:flex links space-x-4 text-[18px] py-[36px]">
                <ul className="flex space-x-4">
                    {/* Hakkımızda */}
                    <li className='px-5 py-[12px]'>
                        
                        <a className="hover:text-blue-500">                            <Link to="/hakkimizda">Hakkımızda</Link> 
                        </a>
                    </li>
                    
                    {/* Faaliyet Alanları */}
                    <div 
                        className="group px-5 py-[12px]"
                        onMouseEnter={() => handleDesktopMenuEnter('faaliyet')}
                        onMouseLeave={handleDesktopMenuLeave}
                    >
                        <button className="hover:text-blue-500 flex items-center">
                            <Link to="/Faaliyet-alani">Faaliyet Alanları</Link> 
                            <img src={arrow} alt="arrow" className="ml-2 w-3 h-3" />
                        </button>
                        <div
                            className={`w-full z-20 mt-20 flex-col absolute left-0 bg-gray-900 top-16 shadow-md transition-all duration-500
                              ${activeDesktopMenu === 'faaliyet' ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                            onMouseEnter={handleMegaMenuMouseEnter}
                            onMouseLeave={handleMegaMenuMouseLeave}
                        >
                            <div className='grid grid-cols-2 gap-0'>
                                <div className='flex flex-col mt-8 mb-[88px] ml-[64px]'>
                                    {faaliyetler.map((faaliyet, index) => (
                                        <li key={faaliyet.id}
                                            onMouseEnter={() => handleFaaliyetHover(index)}>
                                            <Link 
                                                to={`/faaliyet/${faaliyet.id}`} 
                                                className="block px-4 py-2 hover:text-[#88CEEF]"
                                            >
                                                {faaliyet.title}
                                            </Link>
                                        </li>
                                    ))}
                                </div>
                                <div className='flex flex-col mt-[32px] mb-[48px] mr-[144px]'>
                                    {faaliyetler.length > 0 && (
                                        <img 
                                            src={require(`../assets/${faaliyetler[currentFaaliyetIndex]?.images}`)} 
                                            alt={faaliyetler[currentFaaliyetIndex]?.title} 
                                            className="w-[684px] h-[420px] object-cover transition-all duration-300" 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Ürünlerimiz */}
                    <div 
                        className="group px-5 py-[12px]" 
                        onMouseEnter={() => handleDesktopMenuEnter('urunler')}
                        onMouseLeave={handleDesktopMenuLeave}
                    >
                        <button className="hover:text-blue-500 flex items-center">
                            <Link to="/urunler">Ürünlerimiz</Link> 
                            <img src={arrow} alt="arrow" className="ml-2 w-3 h-3" />
                        </button>
                        <div
                            className={`w-full z-20 mt-20 flex-col absolute left-0 bg-gray-900 top-16 shadow-md transition-all duration-500
                              ${activeDesktopMenu === 'urunler' ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                            onMouseEnter={handleMegaMenuMouseEnter}
                            onMouseLeave={handleMegaMenuMouseLeave}
                        >
                            <div className='grid grid-cols-2 gap-0'>
                                <div className='flex flex-col mt-8 mb-[88px] ml-[64px]'>
                                    {urunler.map((urun, index) => (
                                        <li key={urun.id}
                                            onMouseEnter={() => handleUrunHover(index)}>
                                            <Link 
                                                to={`/urun/${urun.id}`} 
                                                className="block px-4 py-2 hover:text-[#88CEEF]"
                                            >
                                                {urun.title}
                                            </Link>
                                        </li>
                                    ))}
                                </div>
                                <div className='flex flex-col mt-[32px] mb-[48px] mr-[144px]'>
                                    {urunler.length > 0 && (
                                        <img 
                                            src={require(`../assets/${urunler[currentUrunIndex]?.imageDetail}`)}
                                            alt={urunler[currentUrunIndex]?.title} 
                                            className="w-[684px] h-[420px] object-cover transition-all duration-300" 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {/* Hizmetlerimiz */}
                    <div 
                        className="group px-5 py-[12px]" 
                        onMouseEnter={() => handleDesktopMenuEnter('hizmetler')}
                        onMouseLeave={handleDesktopMenuLeave}
                    >
                        <button className="hover:text-blue-500 flex items-center">
                            <Link to="/hizmetler">Hizmetlerimiz</Link> 
                            <img src={arrow} alt="arrow" className="ml-2 w-3 h-3" />
                        </button>
                        <div
                            className={`w-full z-20 mt-20 flex-col absolute left-0 bg-gray-900 top-16 shadow-md transition-all duration-500
                              ${activeDesktopMenu === 'hizmetler' ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                            onMouseEnter={handleMegaMenuMouseEnter}
                            onMouseLeave={handleMegaMenuMouseLeave}
                        >
                            <div className='grid grid-cols-2 gap-0'>
                                <div className='flex flex-col mt-8 mb-[88px] ml-[64px]'>
                                    {hizmetlerimiz.map((hizmet, index) => (
                                        <li key={hizmet.id}
                                            onMouseEnter={() => handleHizmetHover(index)}>
                                            <Link 
                                                to={`/hizmetler/${hizmet.id}`} 
                                                className="block px-4 py-2 hover:text-[#88CEEF]"
                                            >
                                                {hizmet.title}
                                            </Link>
                                        </li>
                                    ))}
                                </div>
                                <div className='flex flex-col mt-[32px] mb-[48px] mr-[144px]'>
                                    {hizmetlerimiz.length > 0 && (
                                        <img 
                                            src={require(`../assets/${hizmetlerimiz[currentHizmetIndex]?.image}`)}
                                            alt={hizmetlerimiz[currentHizmetIndex]?.title} 
                                            className="w-[684px] h-[420px] object-cover transition-all duration-300" 
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* İletişim */}
                    <li className='px-5 py-[12px]'>
                        <a className="hover:text-blue-500"> <Link to="/iletisim">İletişim</Link></a>
                    </li>
                </ul>
            </div>

            {/* Desktop Actions (Teklif Al, EN) */}
            <div className="hidden lg:flex actions space-x-4">
                <ul className="flex space-x-4 pr-4">
                    <li className="hover:text-blue-500 border border-white rounded-full px-4 py-2 text-white">
                        <a href="#">Teklif Al</a>
                    </li>
                    <li className="hover:text-blue-500 border border-white rounded-full px-3 py-2">
                        <a href="#teklifal">EN</a>
                    </li>
                </ul>
            </div>

            {/* Mobil Buton */}
            <div className="lg:hidden flex items-center">
                <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                    {isMobileMenuOpen ? (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" 
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" 
                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    )}
                </button>
            </div>

            {/* Mobil Menu */}
            {isMobileMenuOpen && (
                <div className="lg:hidden absolute top-full left-0 right-0 bg-gray-900 text-white z-30 
                                transition-transform transform origin-top scale-y-100 h-[812px] ">
                    {!activeSubMenu ? (
                        <ul className="flex flex-col items-right m-10 relative space-y-4 py-4 w-full">
                            <li>
                                <a href="#hakkimizda" className="hover:text-blue-500">
                                    <Link to="/hakkimizda">Hakkımızda</Link>
                                </a>
                            </li>
                            <li>
                                <button onClick={() => handleSubMenuClick('faaliyet')}
                                        className="hover:text-blue-500 flex items-center">
                                    Faaliyet Alanları 
                                    <img src={arrow} alt="arrow" className="ml-2 w-3 h-3 -rotate-90" />
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleSubMenuClick('urunler')}
                                        className="hover:text-blue-500 flex items-center">
                                    Ürünlerimiz 
                                    <img src={arrow} alt="arrow" className="ml-2 w-3 h-3 -rotate-90" />
                                </button>
                            </li>
                            <li>
                                <button onClick={() => handleSubMenuClick('hizmetler')}
                                        className="hover:text-blue-500 flex items-center">
                                    Hizmetlerimiz 
                                    <img src={arrow} alt="arrow" className="ml-2 w-3 h-3 -rotate-90" />
                                </button>
                            </li>
                            <li>
                                <a className="hover:text-blue-500">
                                    <Link to="/iletisim">İletişim</Link>
                                </a>
                            </li>
                            <li className="hover:text-blue-500 py-2 h-[430px] text-gray-900">
                                <a href="#teklifal"
                                   className='flex items-center bg-white justify-center w-[300px] h-[48px] border border-white rounded-full'>
                                    Teklif Al
                                </a>
                            </li>
                            <li className="hover:text-blue-500 border w-[120px] border-white rounded-full bg-white px-3 py-2 text-gray-900 flex items-center justify-between ">
                                <a href="#teklifal" className="flex-1">Türkçe</a>
                                <img src={chevronDown} alt="chevronDown" className="ml-2 w-3 h-3" />
                            </li>                       
                        </ul>
                    ) : (
                        <div>
                            <button onClick={handleBackToMainMenu} className="flex items-center px-4 py-2">
                                <img src={arrow} alt="arrow" className="w-3 h-3 rotate-90" />
                                <span className="ml-2">Geri</span>
                            </button>
                            <ul className="flex flex-col items-left space-y-4 py-4 px-4">
                                {activeSubMenu === 'faaliyet' && (
                                    <>
                                        <h5 className='py-2 font-bold'><Link to="/faaliyet-alani">Faaliyet Alanları</Link> </h5>
                                        {faaliyetler.map((faaliyet) => (
                                            <li key={faaliyet.id}>
                                                <Link 
                                                    to={`/faaliyet/${faaliyet.id}`} 
                                                    className="hover:text-blue-500"
                                                >
                                                    {faaliyet.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </>
                                )}
                                {activeSubMenu === 'urunler' && (
                                    <>
                                        <h5 className='py-2 font-bold'>  <Link to="/urunler">Ürünlerimiz</Link>
                                        </h5>
                                        {urunler.map((urun) => (
                                            <li key={urun.id}>
                                                <Link 
                                                    to={`/urun/${urun.id}`} 
                                                    className="hover:text-blue-500"
                                                >
                                                    {urun.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </>
                                )}
                                {activeSubMenu === 'hizmetler' && (
                                    <>
                                        <h5 className='py-2 font-bold' ><Link to="/hizmetler">Hizmetlerimiz</Link></h5>
                                        {hizmetlerimiz.map((hizmet) => (
                                            <li key={hizmet.id}>
                                                <Link 
                                                    to={`/hizmetler/${hizmet.id}`} 
                                                    className="hover:text-blue-500"
                                                >
                                                    {hizmet.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Navigation;