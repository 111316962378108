import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../tailwind.css';
import ArrowLeft from '../assets/arrow-left.svg';
import ArrowRight from '../assets/arrow-right.svg';
import ActiveDot from '../assets/active-dot.svg';
import InactiveDot from '../assets/inactive-dot.svg';
import services from '../hizmetler.json'; // hizmetler.json dosyasını import ediyoruz

const HizmetlerSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate(); // useNavigate hook'unu ekliyoruz

  const itemsPerSlide = 4; // Masaüstünde aynı anda 4 kart gösterelim

  // Masaüstü Önceki butonu
  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prev) =>
        (prev - itemsPerSlide + services.length) % services.length
      );
      setIsAnimating(false);
    }, 400);
  };

  // Masaüstü Sonraki butonu
  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + itemsPerSlide) % services.length);
      setIsAnimating(false);
    }, 400);
  };

  // Masaüstünde gösterilecek 4 hizmeti hesapla
  const desktopItems = services.slice(currentIndex, currentIndex + itemsPerSlide);
  // Eğer slice sonuna geldiyseniz, kalan hizmet sayısını baştan tamamla (wrap-around efekti)
  if (desktopItems.length < itemsPerSlide) {
    desktopItems.push(
      ...services.slice(0, itemsPerSlide - desktopItems.length)
    );
  }

  // Hizmet kartına tıklandığında detay sayfasına yönlendir
  const handleServiceClick = (id) => {
    navigate(`/hizmetler/${id}`);
  };

  // Metni belirli bir uzunlukta kısaltma fonksiyonu
  function TruncateByLength({ text, maxLength = 100 }) {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#F5FBFE]">
      <div className="bg-[#F5FBFE] w-full max-w-[1680px] h-auto p-4 md:p-12 lg:pt-[120px] lg:pb-[160px] lg:px-[180px] flex flex-col items-center gap-8">
        
        {/* Üst Kısım */}
        <div className="w-full max-w-[1320px] h-auto flex flex-col gap-8">
          <div className="flex flex-col md:flex-row justify-between items-center w-full gap-4 md:gap-0">
            <h2 className="text-left md:text-left font-inter text-2xl sm:text-3xl md:text-4xl font-semibold leading-snug md:leading-[48px] text-[#06202C]">
              Uzmanlığımız ve uluslararası çözümlerimizle güçlendirdiğimiz <br className="hidden md:block" />
              olağanüstü işletmelere tanık olun. Biz birlikte büyürüz.
            </h2>
            <Link
              to="/hizmetler"
              className="hidden md:inline text-right font-inter text-lg font-medium leading-[24px] text-[#384D56]"
            >
              Tüm hizmetleri gör
            </Link>
          </div>

          {/* 
            ==================================
            MASAÜSTÜ BÖLÜMÜ (md:block)
            4'lü grid + oklar + noktalar
            ==================================
          */}
          <div className="hidden md:block">
            {/* Kartlar (4'lü Grid) */}
            <div
              className={`grid grid-cols-4 gap-6 transition-opacity duration-500 ${
                isAnimating ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {desktopItems.map((service) => (
                <div
                  key={service.id}
                  className="bg-white w-full h-[350px] md:h-[424px] cursor-pointer"
                  onClick={() => handleServiceClick(service.id)} // Hizmet detayına yönlendir
                >
                  <img
                    src={require(`../assets/${service.image}`)} // Resim yolunu dinamik olarak al
                    alt={service.title}
                    className="w-full h-[200px] md:h-[240px] object-cover"
                  />
                  <div className="pb-0 px-3 pt-1 flex flex-col gap-3">
                    <span className="text-[15px] font-inter font-normal text-left opacity-80">
                      {service.category}
                    </span>
                    <h3 className="text-[20px] font-inter font-medium leading-6 text-left text-[#06202C]">
                      {service.title}
                    </h3>
                    <p className="text-[16px] font-inter font-normal text-left leading-[22px] text-[#06202C] opacity-80">
                      <TruncateByLength text={service.descriptionShort} maxLength={70} />
                    </p>
                  </div>
                </div>
              ))}
            </div>

            {/* Masaüstü oklar ve noktalar (pagination) */}
            <div className="flex justify-center items-center gap-7 mt-6">
              <button onClick={handlePrev}>
                <img src={ArrowLeft} alt="Previous" />
              </button>

              {/* Dot Indicators */}
              {Array.from({
                length: Math.ceil(services.length / itemsPerSlide),
              }).map((_, index) => (
                <img
                  key={index}
                  src={
                    index === Math.floor(currentIndex / itemsPerSlide)
                      ? ActiveDot
                      : InactiveDot
                  }
                  alt="Dot indicator"
                />
              ))}

              <button onClick={handleNext}>
                <img src={ArrowRight} alt="Next" />
              </button>
            </div>
          </div>

          {/* 
            ==================================
            MOBİL BÖLÜMÜ (md:hidden)
            Tekli görünüm, yatay kaydırma
            ==================================
          */}
          <div className="md:hidden overflow-x-auto snap-x snap-mandatory">
            <div className="flex w-full">
              {services.map((service) => (
                <div
                  key={service.id}
                  className="flex-shrink-0 w-full snap-center bg-white h-[350px] cursor-pointer"
                  onClick={() => handleServiceClick(service.id)} // Hizmet detayına yönlendir
                >
                  <img
                    src={require(`../assets/${service.image}`)} // Resim yolunu dinamik olarak al
                    alt={service.title}
                    className="w-full h-[200px] object-cover"
                  />
                  <div className="pb-0 px-3 pt-1 flex flex-col gap-3">
                    <span className="text-[15px] font-inter font-normal text-left opacity-80">
                      {service.category}
                    </span>
                    <h3 className="text-[20px] font-inter font-medium leading-6 text-left text-[#06202C]">
                      {service.title}
                    </h3>
                    <p className="text-[16px] font-inter font-normal text-left leading-[22px] text-[#06202C] opacity-80">
                      <TruncateByLength text={service.descriptionShort} maxLength={70} />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mobil alt link */}
        <Link
          to="/hizmetler"
          className="md:hidden text-center pt-10 font-inter text-lg font-medium leading-[24px] text-[#384D56]"
        >
          Tüm hizmetleri gör
        </Link>
      </div>
    </div>
  );
};

export default HizmetlerSlider;