import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../tailwind.css';
import ArrowLeft from '../assets/arrow-left.svg';
import ArrowRight from '../assets/arrow-right.svg';
import ActiveDot from '../assets/active-dot.svg';
import InactiveDot from '../assets/inactive-dot.svg';
import mockProducts from '../mockProducts.json'; 

const UrunlerSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // Masaüstünde aynı anda gösterilecek kart sayısı
  const itemsPerSlide = 4;

  // Masaüstü: Önceki buton
  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prev) =>
        (prev - itemsPerSlide + mockProducts.length) % mockProducts.length
      );
      setIsAnimating(false);
    }, 400);
  };

  // Masaüstü: Sonraki buton
  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + itemsPerSlide) % mockProducts.length);
      setIsAnimating(false);
    }, 400);
  };
  
  // Masaüstünde görüntülenecek ürünleri hesapla
  const desktopItems = mockProducts.slice(currentIndex, currentIndex + itemsPerSlide);

  // Wrap-around (Eğer son slide'da 4 ürünü tamamlayamazsa, baştan ekler)
  if (desktopItems.length < itemsPerSlide) {
    desktopItems.push(
      ...mockProducts.slice(0, itemsPerSlide - desktopItems.length)
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#F5FBFE]">
      <div className="bg-[#F5FBFE] w-full max-w-[1680px] h-auto p-4 md:p-12 lg:pt-[120px] lg:pb-[160px] lg:px-[180px] flex flex-col items-center gap-8">
        
        {/* ÜST KISIM */}
        <div className="w-full max-w-[1320px] h-auto flex flex-col gap-8">
          {/* Başlık ve üst link */}
          <div className="flex flex-col md:flex-row justify-between items-center w-full gap-4 md:gap-0">
            <h2 className="text-left font-inter text-2xl sm:text-3xl md:text-4xl font-semibold leading-snug md:leading-[48px] text-[#06202C]">
              Gelişmiş Teknoloji ile Donatılmış <br className="hidden md:block" />
              Havalandırma Ürünlerimiz
            </h2>
            {/* Masaüstünde görünür */}
            <Link
              to="/urunler"
              className="hidden md:inline text-right font-inter text-lg font-medium leading-[24px] text-[#384D56]"
            >
              Tüm ürünleri gör
            </Link>
          </div>

          {/*
            ==================================
            MASAÜSTÜ BÖLÜMÜ (md:block)
            4'lü grid + oklar + noktalar
            ==================================
          */}
          <div className="hidden md:block">
            {/* Kartlar (4'lü Grid) - Genişlik 312px olarak sabitlendi */}
            <div
              className={`grid grid-cols-4 gap-6 transition-opacity duration-500 ${
                isAnimating ? 'opacity-0' : 'opacity-100'
              }`}
            >
              {desktopItems.map((product) => (
                <Link
                  key={product.id}
                  to={`/urun/${product.id}`}
                  className="bg-white w-[312px] h-[460px] cursor-pointer block"
                >
                  <img
                    src={require(`../assets/${product.images[0]}`)} 
                    alt={product.title}
                    className="w-full h-[240px] object-cover"
                  />
                  <div className="pb-0 px-4 pt-3 flex flex-col gap-3">
                    <span className="text-[15px] font-inter font-normal text-left opacity-80">
                      {product.category}
                    </span>
                    <h3 className="text-[20px] font-inter font-medium leading-6 text-left text-[#06202C]">
                      {product.title}
                    </h3>
                    <p className="text-[16px] font-inter font-normal text-left leading-[22px] text-[#06202C] opacity-80 line-clamp-4">
                     {product.cardDescription}
                    </p>
                  </div>
                </Link>
              ))}
            </div>

            {/* Oklar ve noktalar (pagination) */}
            <div className="flex justify-center items-center gap-7 mt-6">
              <button onClick={handlePrev}>
                <img src={ArrowLeft} alt="Previous" />
              </button>
              {Array.from({
                length: Math.ceil(mockProducts.length / itemsPerSlide),
              }).map((_, index) => (
                <img
                  key={index}
                  src={
                    index === Math.floor(currentIndex / itemsPerSlide)
                      ? ActiveDot
                      : InactiveDot
                  }
                  alt="Dot indicator"
                />
              ))}
              <button onClick={handleNext}>
                <img src={ArrowRight} alt="Next" />
              </button>
            </div>
          </div>

          {/*
            ==================================
            MOBİL BÖLÜMÜ (md:hidden)
            Yatay kaydırma (tek kart ekranda) - Kaydırma göstergesi eklendi
            ==================================
          */}
          <div className="md:hidden relative">
            {/* Kaydırma ipucu için ikon veya gölge */}
            <div className="absolute right-0 top-0 bottom-0 w-10 bg-gradient-to-l from-[#F5FBFE] to-transparent z-10 pointer-events-none flex items-center">
           
            </div>
            
            {/* Kaydırılabilir içerik */}
            <div className="overflow-x-auto snap-x snap-mandatory hide-scrollbar pb-4">
              <div className="flex space-x-4 pl-2 pr-12">
                {mockProducts.map((product) => (
                  <Link
                    key={product.id}
                    to={`/urun/${product.id}`}
                    className="flex-shrink-0 w-[312px] snap-start bg-white h-[440px] cursor-pointer block rounded-sm shadow-sm"
                  >
                    <img 
                      src={require(`../assets/${product.images[0]}`)} 
                      alt={product.title}
                      className="w-full h-[200px] object-cover"
                    />
                    <div className="pb-0 px-4 pt-3 flex flex-col gap-3">
                      <span className="text-[15px] font-inter font-normal text-left opacity-80">
                        {product.category}
                      </span>
                      <h3 className="text-[20px] font-inter font-medium leading-6 text-left text-[#06202C]">
                        {product.title}
                      </h3>
                      <p className="text-[16px] font-inter font-normal text-left leading-[22px] text-[#06202C] opacity-80 line-clamp-4">
                       {product.cardDescription}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Mobilde görünecek alt link */}
        <Link
          to="/urunler"
          className="md:hidden text-center pt-6 font-inter text-lg font-medium leading-[24px] text-[#384D56]"
        >
          Tüm ürünleri gör
        </Link>
      </div>
    </div>
  );
};

// CSS için stil eklentisi (tailwind.css dosyasına eklenebilir)
// Kaydırma çubuğunu gizlemek için
/*
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
*/

export default UrunlerSlider;