import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import { Link } from 'react-router-dom';
import Showcase from '../assets/hakkimizda.svg';

// JSON verilerini import ediyoruz
import services from '../hizmetler.json';

function HizmetDetay() {
    const { id } = useParams(); // URL'den hizmet ID'sini alıyoruz
    const service = services.find(service => service.id === parseInt(id)); // ID'ye göre hizmeti buluyoruz

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    if (!service) {
        return <div>Hizmet bulunamadı.</div>;
    }

    return (
        <>
                    <div className="relative w-full h-720 bg-cover bg-center bg-[#1A6196]" style={{ backgroundImage: `url(${Showcase})`, height: '720px', backgroundRepeat: 'no-repeat' }}>

            <Navigation />
                           {/* Breadcrumb */}
                           <div className="absolute top-15 left-4 md:left-40 flex items-center space-x-2">
                    <Link to="/" className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">
                        Ana Sayfa
                    </Link>
                    <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]">•</span>
                    <Link to="/hizmetler" className="text-[#F9F9F9] font-inter text-[14px] font-semibold leading-[16px]">
                        hizmetlerimiz   
                        <span className="text-[#DDDDDD] font-inter text-[14px] font-semibold leading-[16px]"> • </span>
                         {service.title}
                    </Link>
                    
                </div>
    {/* Başlık */}
    <div className="h-full pl-4 md:pl-60 pt-40">
    {/* Başlık */}
    <h1 className="text-white text-2xl sm:text-4xl md:text-5xl lg:text-6xl text-center mb-8">
        {service.title}
    </h1>

    {/* Resim */}
    <div className="w-full overflow-visible">
        <img 
            src={require(`../assets/${service.image}`)} 
            alt={service.title} 
            className="w-full max-w-4xl mx-auto object-contain" 
            style={{ maxHeight: '45vh' }} // Resmin maksimum yüksekliğini sınırla
        />
    </div>
</div>

                </div>

            <div className="w-full h-auto flex justify-center items-start py-7 bg-white">
                <div className="w-full md:w-[1320px] h-auto flex flex-col justify-center items-start py-20 gap-20">
                    <div className="w-full h-auto flex flex-col items-center">
                        <div className="w-full p-4">
                            <p className="text-lg text-gray-600" dangerouslySetInnerHTML={{ __html: service.descriptionLong}}/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
          
        </>
    );
}

export default HizmetDetay;